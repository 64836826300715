.PopUp .Confirmation{
    width: 400px;
}
.PopUp .Confirmation .title{
    justify-content: center;
}
.PopUp .Confirmation .div_confirmation{
    flex-direction: row;
}
.PopUp .Confirmation .div_confirmation{
    flex-direction: row;
}
.PopUp .Confirmation .select_idioma{
    color: #6c757d;
    border: 1px solid #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .Confirmation .yes_update {
    color: #ffffff;
}
.PopUp .Confirmation .text_inf{
    margin-bottom: 16px;
}
.PopUp .Confirmation .new_block_text{
    width: 100%;
    margin-top: 16px;
    padding: 6px 10px;
    border-radius: 8px;
}